<template lang="html">
    <div class="pricing-wrap">
        <div class="container">
            <h1 class="d-flex-center m-4 pt-5">方案</h1>
            <div class="row">
                <div class="col-md-4">
                    <div class="pricing-card free-wrap">
                        <h4 class="free-title">精簡版</h4>
                        <div class="content-wrap">
                          <div class="original-title d-flex-center">原價$0</div>
                          <div class="discount-title"><span class="coin">$</span><span>0</span><span class="currency">TWD</span></div>
                          <div class="about-wrap d-flex-center"><img src="@/assets/image/pricing-img-free.png"><div class="about">免費體驗、做任務拿積分、樂於分享互相交換學習</div></div>
                          <ul class="table-list">
                            <li><div class="pl-3">註冊立即贈送 1000點免費點數<br/>(約 3 部教材)</div></li>
                            <li><div class="pl-3">分析式學習，就像你的貼身助理</div></li>
                            <li><div class="pl-3">延伸式學習，超快記憶法</div></li>
                            <li><div class="pl-3">豐富情景語料中了解語義</div></li>
                            <li><div class="pl-3">社群追蹤討論，讓你學習不孤單</div></li>
                          </ul>
                          <div class="w-100 d-flex-center">
                            <button class="btn">馬上開始</button>    
                          </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="pricing-card easy-wrap">
                        <h4 class="easy-title">輕鬆學習方案</h4>
                        <div class="content-wrap">
                          <div class="original-title d-flex-center">原價$999</div>
                          <div class="discount-title"><span class="coin">$</span><span>799</span><span class="currency">TWD</span></div>
                          <div class="about-wrap d-flex-center"><img src="@/assets/image/pricing-img-easy.png"><div class="about">想要無負擔的輕鬆學習，了解文化、接觸文化</div></div>
                          <ul class="table-list">
                            <li><div class="pl-3">換得 10000 個金幣<br/>(約可看30部教材)</div></li>
                            <li><div class="pl-3">分析式學習，就像你的貼身助理</div></li>
                            <li><div class="pl-3">延伸式學習，超快記憶法</div></li>
                            <li><div class="pl-3">豐富情景語料中了解語義</div></li>
                            <li><div class="pl-3">社群追蹤討論，讓你學習不孤單</div></li>
                          </ul>
                          <div class="w-100 d-flex-center">
                            <button class="btn">立即訂閱</button>    
                          </div>
                        </div>
                    </div>
                </div> 
                <div class="col-md-4">
                    <div class="pricing-card recommend-wrap">
                        <h4 class="recommend-title">推薦方案</h4>
                        <div class="content-wrap">
                          <div class="original-title d-flex-center">原價$2,499</div>
                          <div class="discount-title"><span class="coin">$</span><span>1,999</span><span class="currency">TWD</span></div>
                          <div class="about-wrap d-flex-center"><img src="@/assets/image/pricing-img-recommend.png"><div class="about">想要大量累積詞彙量的你，考前衝刺，工作需要</div></div>
                          <ul class="table-list">
                            <li><div class="pl-3">換得 30000個金幣<br/>(約可看100部教材)</div></li>
                            <li><div class="pl-3">分析式學習，就像你的貼身助理</div></li>
                            <li><div class="pl-3">延伸式學習，超快記憶法</div></li>
                            <li><div class="pl-3">豐富情景語料中了解語義</div></li>
                            <li><div class="pl-3">社群追蹤討論，讓你學習不孤單</div></li>
                          </ul>
                          <div class="w-100 d-flex-center">
                            <button class="btn">立即訂閱</button>    
                          </div>
                        </div>
                    </div>
                </div> 
            </div>
            <h1 class="d-flex-center m-4 pt-3">詳細</h1>
            <div class="detail-card">
              <div class="detail-table-wrap" :class="{ openHeight :isOpen}">
                  <table class="table">
                    <thead>
                      <tr class="title">
                        <th>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                        <th class="free">精簡版</th>
                        <th class="easy">輕鬆學習方案</th>
                        <th class="recommend">推薦方案</th>
                      </tr>
                    </thead>
                    <tbody class="content">
                      <tr>
                        <th scope="row">語料搜尋<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free"><i class="fa fa-check"></i></td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">分享版分析<br/>學習頁面<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free"><i class="fa fa-check"></i></td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">排行榜<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free"><i class="fa fa-check"></i></td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">使用者個人<br/>檔案<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free"><i class="fa fa-check"></i></td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">學習設定<br/>(電子報設定)<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">個人學習檔案<br/>(學習進度)<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">使用者追蹤<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">分析學習頁面<br/>-影片播放<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">影片聽力練習<br/>(舊的播放頁面)<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">詞彙練習<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">收藏單字<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">標註經典例句<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">延伸學習<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">詞彙進階資訊<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">影片庫瀏覽<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">臉書私密社團-<br/>可申請加入<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free"><i class="fa fa-check"></i></td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                      <tr>
                        <th scope="row">語言交換<br/>(贈送)<button type="button" class="btn btn-question" data-container="body" data-toggle="popover" data-placement="right" data-content="Vivamus sagittis lacus vel augue laoreet rutrum faucibus."><i class="fas fa-question"></i></button></th>
                        <td class="free">-</td>
                        <td class="easy"><i class="fa fa-check"></i></td>
                        <td class="recommend"><i class="fa fa-check"></i></td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="detail-gradient" :class="{ changeGradient :isOpen}">
                    <button class="btn btn-openTable" @click="openTable"><i class="fa fa-chevron-down"></i></button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: {
    user: {
      type: Object,
      default: null,
    },
  },
  components: {},
  data: function () {
    return {
      isOpen: false,
    };
  },
  methods: {
    openTable: function () {
      this.isOpen = !this.isOpen;
    },
    initData: function () {},
  },
  mounted: function () {
    this.initData();
  },
  watch: {},
  created() {},
};
</script>


<style>
.pricing-wrap {
  background-color: #f7f8fa;
  padding-bottom: 40px;
}

.pricing-card {
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.recommend-title {
  background-color: #fd6b69;
  color: #fff;
  padding: 16px 0px;
  text-align: center;
  font-size: 1.2rem;
}

.easy-title {
  background-color: #febb2c;
  color: #fff;
  padding: 16px 0px;
  text-align: center;
  font-size: 1.2rem;
}

.free-title {
  background-color: #20cca5;
  color: #fff;
  padding: 16px 0px;
  text-align: center;
  font-size: 1.2rem;
}

.original-title {
  color: #b5b5b5;
  font-size: 1.1rem;
  text-decoration: line-through;
  width: 100%;
  padding: 8px 20px 0px 20px;
}

.discount-title {
  color: #484848;
  font-size: 4.7rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: baseline;
  font-family: "Arial";
}

.currency {
  font-size: 1.2rem;
  margin-left: 5px;
}

.coin {
  font-size: 2.1rem;
  margin-right: 3px;
}

.about-wrap img {
  width: 40px;
  height: 40px;
  margin-left: 20px;
}

.about-wrap {
  padding-bottom: 30px;
  border-bottom: 3px solid #000;
  margin-bottom: 25px;
}

.free-wrap .about-wrap {
  border-color: #20cca5;
}

.free-wrap button {
  background-color: #20cca5;
  border-radius: 50px;
  color: #fff;
  width: 66%;
}

.free-wrap button:hover {
  background-color: #17aa88;
  color: #fff;
}

.easy-wrap .about-wrap {
  border-color: #febb2c;
}

.easy-wrap button {
  background-color: #febb2c;
  border-radius: 50px;
  color: #fff;
  width: 66%;
}

.easy-wrap button:hover {
  background-color: #da9e1e;
  color: #fff;
}

.recommend-wrap .about-wrap {
  border-color: #fd6b69;
}

.recommend-wrap button {
  background-color: #fd6b69;
  border-radius: 50px;
  color: #fff;
  width: 66%;
}

.recommend-wrap button:hover {
  background-color: #dd5653;
  color: #fff;
}

.about {
  padding-left: 10px;
  color: #888888;
}

.content-wrap {
  padding: 0px 30px 40px 30px;
}

.table-list li:before {
  content: " ";
  background-image: url("~@/assets/image/pricing-check.png");
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  padding-right: 10px;
  background-size: contain;
  color: #3fab91;
  display: inline-block;
  position: relative;
  top: 5px;
  right: -3px;
  font-size: 16px;
}

.table-list li {
  display: flex;
  padding-bottom: 10px;
}

/* 表格 */

.detail-table-wrap {
  height: 330px;
  overflow: hidden;
  position: relative;
}

.detail-gradient {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  height: 200px;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+19,ffffff+100&0+0,1+99 */
  background: -moz-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.19) 19%,
    rgba(255, 255, 255, 1) 99%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.19) 19%,
    rgba(255, 255, 255, 1) 99%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.19) 19%,
    rgba(255, 255, 255, 1) 99%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
}

.changeGradient {
  height: 30px;
}

.detail-gradient.changeGradient button {
  transform: rotate(180deg);
}

.detail-gradient.changeGradient button:hover,
.detail-gradient button:hover {
  color: #fff;
  background-color: #3559ce;
}

.hideGradient {
  display: none;
}

.openHeight {
  height: auto;
}

.detail-card {
  padding: 30px 30px 70px 30px;
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 130px;
}

.detail-card .table th,
.detail-card .table td {
  border-top: none;
}

.detail-card .table td {
  border-top: none;
  text-align: center;
  vertical-align: inherit;
}

.detail-card .title th {
  text-align: center;
}

td.free {
  color: #17aa88;
}

.title .free {
  background-color: #17aa88;
  color: #fff;
}

td.easy {
  color: #febb2c;
}

.title .easy {
  background-color: #febb2c;
  color: #fff;
}

td.recommend {
  color: #dd5653;
}

.title .recommend {
  background-color: #dd5653;
  color: #fff;
}

.title .free,
.title .recommend,
.title .easy {
  width: 28%;
}

.detail-card .table tr:nth-child(2n) {
  background-color: #f7f7f7;
}

.content tr th {
  border-top: none;
  display: inline-flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.btn-question {
  background-color: #ebebeb;
  color: #c3c3c3;
  border-radius: 50%;
  width: 26px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0px;
}

.btn-openTable {
  background-color: #456ceb;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 992px) {
  .content tr th button {
    display: none;
  }
  .detail-card .title th {
    font-size: 0.8rem;
  }
  .title .free,
  .title .recommend,
  .title .easy {
    width: 22%;
  }
  .detail-card .table th {
    font-size: 0.8rem;
  }
}
</style>